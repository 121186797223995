import React, { useEffect, useRef, useState } from "react"
import { twMerge } from "tailwind-merge"

import useIntersectionObserver from "../../hooks/useIntersectionObserver"
import HealMeVerifiedBadge from "../ProProfileShared/HealMeVerifiedBadge"
import Review from "../ProProfileShared/Review"
import SectionTitle from "../ProProfileShared/SectionTitle"
import SeeMore from "../ProProfileShared/SeeMore"

const ReviewsSection = ({ practice, theme, className, backgroundColor, color }) => {
  backgroundColor ||= theme.colorSecondaryDark
  color ||= theme.colorTextLight

  const isMobile = window.innerWidth < 768
  const defaultReviewsShown = isMobile ? 5 : 21
  const reviews = practice.reviews.filter((review) => review.review)
  const [expanded, setExpanded] = useState(false)

  const [isVisible, containerRef] = useIntersectionObserver()

  const imageUrl = practice.reviewsSectionImageUrl

  const reviewsContainerRef = useRef(null)
  const imageContainerRef = useRef(null)
  const [imageHeight, setImageHeight] = useState("auto")

  useEffect(() => {
    if (reviewsContainerRef.current) {
      reviewsContainerRef.current.style.maxHeight = expanded ? `${reviewsContainerRef.current.scrollHeight}px` : "0px"
    }
  }, [expanded, reviews])

  useEffect(() => {
    if (imageContainerRef.current) {
      setImageHeight(`${imageContainerRef.current.offsetHeight}px`)
    }
  }, [])

  return (
    <section
      id="reviews"
      className={twMerge("w-full scroll-mt-16 md:px-5", className)}
      style={{ backgroundColor, color }}>
      <div className="flex flex-col min_md:flex-row">
        <div ref={imageContainerRef} className="md:h-[240px] min_md:w-1/2" style={{ height: imageHeight }}>
          <img src={imageUrl} alt="Reviews Image" className="h-full w-full object-cover" />
        </div>
        <div className="pt-24 md:pt-10 min_md:w-1/2 min_md:pl-16 min_md:pr-32">
          <SectionTitle className="mb-10 text-left md:text-center" theme={theme}>
            My Reviews
          </SectionTitle>
          <div
            ref={containerRef}
            className={`container mx-auto grid grid-cols-1 gap-x-10 gap-y-16 transition-all duration-1000 md:gap-y-8 ${
              isVisible ? "translate-y-0 opacity-100" : "-translate-y-24 opacity-0"
            }`}>
            {reviews.slice(0, 5).map((review) => (
              <Review
                key={`review-${review.id}`}
                review={review}
                practice={practice}
                starColor={theme.colorReviewStars}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="p-24 md:px-0 md:pt-8">
        <div className="grid grid-cols-2 gap-x-32 gap-y-16 md:grid-cols-1 md:gap-y-8">
          {reviews.slice(5, defaultReviewsShown).map((review) => (
            <Review
              key={`review-${review.id}`}
              review={review}
              practice={practice}
              starColor={theme.colorReviewStars}
            />
          ))}
        </div>
        {expanded && (
          <div
            ref={reviewsContainerRef}
            className="grid grid-cols-2 gap-x-32 gap-y-16 overflow-hidden transition-all duration-1000 md:grid-cols-1 md:gap-y-8"
            style={{ maxHeight: 0 }}>
            {reviews.slice(defaultReviewsShown).map((review) => (
              <Review
                key={`review-${review.id}`}
                review={review}
                practice={practice}
                starColor={theme.colorReviewStars}
              />
            ))}
          </div>
        )}
        {reviews.length > defaultReviewsShown && (
          <SeeMore
            className="my-10 flex text-opacity-50 md:justify-center min_md:justify-start"
            buttonClassName="hover:text-[var(--hover-color)]"
            buttonStyle={{ color, "--hover-color": theme.colorAccent }}
            onClick={() => {
              if (expanded) document.getElementById("reviews").scrollIntoView({ behavior: "smooth" })
              setExpanded(!expanded)
            }}
          />
        )}
        <div className="mt-8 flex w-full justify-start md:justify-center">
          <HealMeVerifiedBadge
            text="Reviews are Heal.me verified"
            theme={theme}
            backgroundColor={backgroundColor}
            color={color}
          />
        </div>
      </div>
    </section>
  )
}

export default ReviewsSection
