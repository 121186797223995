import difference from "lodash/difference"
import React, { useState } from "react"
import { twMerge } from "tailwind-merge"

import { Button } from "../../components/shared/Buttons"

import { useWebsiteBuilder } from "./WebsiteBuilderContext"

const excludedValues = ["id", "practiceId", "name", "buttonStyle", "preview", "createdAt", "updatedAt", "fontUrlParams"]

const isStyleSelected = (practice, selectedTheme) =>
  difference(Object.keys(practice.theme), excludedValues).every((key) => practice.theme[key] === selectedTheme[key])

const StyleTab = () => {
  const { allStyles, practice, lastSavedPractice, setPractice, updateTheme, isPreviewing, setIsPreviewing } =
    useWebsiteBuilder()
  const [previewedStyleName, setPreviewedStyleName] = useState(null)

  const [selectedStyleName, setSelectedStyleName] = useState(
    allStyles.find((theme) => isStyleSelected(lastSavedPractice, theme))?.name
  )

  return (
    <div>
      <div className="flex flex-col">
        {allStyles.map((theme, index) => (
          <div
            key={`${index}-styles`}
            className="w-full py-2"
            onMouseEnter={() => {
              if (selectedStyleName === theme.name) return

              setPreviewedStyleName(theme.name)
              const newTheme = {
                ...theme,
                name: practice.theme.name
              }
              setPractice((prev) => ({
                ...prev,
                theme: newTheme
              }))
              updateTheme({ ...newTheme, preview: true })
              setIsPreviewing(true)
            }}
            onMouseLeave={() => {
              setIsPreviewing(false)
              setPreviewedStyleName(null)
              setPractice((prev) => ({
                ...prev,
                theme: allStyles.find((style) => style.name === selectedStyleName)
              }))
            }}>
            <div
              onClick={() => {
                const newTheme = {
                  ...theme,
                  name: practice.theme.name,
                  buttonStyle: practice.theme.buttonStyle
                }
                setPractice((prev) => ({
                  ...prev,
                  theme: newTheme
                }))
                setIsPreviewing(false)
                setPreviewedStyleName(null)
                setSelectedStyleName(theme.name)
                updateTheme(newTheme)
              }}
              className={twMerge(
                "inline-flex h-[117px] w-full cursor-pointer items-center justify-start gap-4 rounded-xl border border-gray-light bg-white p-4 transition duration-300 ease-out md:h-fit",
                selectedStyleName === theme.name && previewedStyleName !== theme.name
                  ? "border-teal bg-gray-ultralight"
                  : "border-gray-light",
                !isStyleSelected(practice, theme) && isPreviewing ? "opacity-25" : ""
              )}>
              <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1">
                <div style={{ fontFamily: theme.fontHeading, fontWeight: theme.fontHeadingWeight }}>
                  Headline font style
                </div>
                <div
                  style={{ fontFamily: theme.fontParagraph }}
                  className={twMerge(
                    "self-stretch text-sm font-normal leading-[21px] text-gray-dark",
                    theme.fontParagraphRegularWeight === "300" ? "font-light" : ""
                  )}>
                  Description font style
                </div>
                <div className="inline-flex items-center justify-start gap-2.5 pt-2">
                  <div className="flex h-6 w-6 items-center justify-center">
                    <div
                      style={{ backgroundColor: theme.colorPrimary || theme.colorPrimaryDark }}
                      className="h-6 w-6 rounded-full border border-gray shadow-inner"
                    />
                  </div>
                  <div className="flex h-6 w-6 items-center justify-center">
                    <div
                      style={{ backgroundColor: theme.colorSecondary || theme.colorSecondaryDark }}
                      className="h-6 w-6 rounded-full border border-gray shadow-inner"
                    />
                  </div>
                  <div className="flex h-6 w-6 items-center justify-center">
                    <div
                      style={{ backgroundColor: theme.colorAccent }}
                      className="h-6 w-6 rounded-full border border-gray shadow-inner"
                    />
                  </div>
                  <div className="flex h-6 w-6 items-center justify-center">
                    <div
                      style={{ backgroundColor: theme.colorBgLight }}
                      className="h-6 w-6 rounded-full border border-gray shadow-inner"
                    />
                  </div>
                </div>
              </div>
              {selectedStyleName === theme.name && previewedStyleName !== theme.name ? (
                <div className="inline-flex flex-col items-start justify-start gap-2">
                  <div className="inline-flex items-center justify-center gap-2 self-stretch rounded bg-black px-4 font-bold leading-loose text-white">
                    Selected
                  </div>
                </div>
              ) : (
                <div className="inline-flex flex-col items-start justify-start gap-2">
                  <Button className="self-stretch">Select</Button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default StyleTab
