import React from "react"

import { ToastProvider } from "../../contexts/ToastContext"
import GraphQLProvider from "../../providers/GraphQLProvider"
import BottomCTA from "../ProProfileShared/BottomCTA"
import EndorsementsSection from "../ProProfileShared/EndorsementsSection"
import Footer from "../ProProfileShared/Footer"
import GallerySection from "../ProProfileShared/GallerySection"
import HeroSection from "../ProProfileShared/HeroSection"
import Map from "../ProProfileShared/Map"
import Navigation from "../ProProfileShared/Navigation"
import Quote from "../ProProfileShared/Quote"
import TopReviewsSection from "../ProProfileShared/TopReviewsSection"

import AboutSection from "./AboutSection"
import ReviewsSection from "./ReviewsSection"
import ServicesSection from "./ServicesSection"
import ValueStatementSection from "./ValueStatementSection"

const ProProfileThemeTwo = ({ practice, highlight, styles, viewingInBuilder }) => {
  const theme = practice.theme
  const isMobile = window.innerWidth < 768

  return (
    <GraphQLProvider>
      <ToastProvider>
        <main style={styles}>
          <HeroSection
            practice={practice}
            theme={theme}
            highlight={highlight}
            viewingInBuilder={viewingInBuilder}
            Navigation={() => <Navigation practice={practice} theme={theme} viewingInBuilder={viewingInBuilder} />}
          />
          <TopReviewsSection
            practice={practice}
            theme={theme}
            backgroundColor={theme.colorSecondaryDark}
            color={theme.colorTextLight}
          />
          <ServicesSection practice={practice} theme={theme} viewingInBuilder={viewingInBuilder} />
          <ValueStatementSection
            practice={practice}
            theme={theme}
            highlight={highlight}
            viewingInBuilder={viewingInBuilder}
          />
          <AboutSection practice={practice} theme={theme} highlight={highlight} viewingInBuilder={viewingInBuilder} />
          <ReviewsSection practice={practice} theme={theme} />
          <EndorsementsSection
            practice={practice}
            theme={theme}
            backgroundColor={theme.colorSecondaryDark}
            color={theme.colorTextLight}
          />
          <GallerySection
            practice={practice}
            theme={theme}
            highlight={highlight === "Gallery photos"}
            containerClassName="gap-16 max-w-[800px] mx-auto md:gap-4"
            imageClassName="h-[380px] w-[360px] md:h-[152px] md:w-full"
            maxHeight={isMobile ? "656px" : "824px"}
          />
          <Quote
            theme={theme}
            text={practice.quote}
            author={practice.quoteAuthor}
            id="quote"
            highlight={highlight === "quote"}
            backgroundColor={theme.colorPrimaryDark}
            color={theme.colorTextLight}
          />
          <BottomCTA practice={practice} theme={theme} highlight={highlight} viewingInBuilder={viewingInBuilder} />
          <Map practice={practice} theme={theme} />
          <Footer
            practice={practice}
            theme={theme}
            backgroundColor={theme.colorBgLight}
            viewingInBuilder={viewingInBuilder}
          />
        </main>
      </ToastProvider>
    </GraphQLProvider>
  )
}

export default ProProfileThemeTwo
