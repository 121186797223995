import React, { useCallback, useRef } from "react"

import { EditableValue, Label, TextArea } from "../../components/shared/Inputs"

import { useWebsiteBuilder } from "./WebsiteBuilderContext"

const ContentInput = ({
  name,
  fieldName,
  text,
  scrollToId,
  maxLength,
  InputTag = TextArea,
  textAreaRows = 1,
  inputLabel,
  inputValue,
  onChange,
  children
}) => {
  const { practice, setPractice, openField, setOpenField, lastSavedPractice, fetching, onSave, setHighlightedField } =
    useWebsiteBuilder()
  fieldName ||= name.toLowerCase()
  const inputRef = useRef(null)

  const characterCount = practice[fieldName]?.length || 0
  const isMaxReached = maxLength && characterCount >= maxLength

  const handleInputChange = useCallback(
    (e) => {
      const newValue = e.target.value
      if (maxLength) {
        const truncatedValue = newValue.slice(0, maxLength)
        setPractice((practice) => ({ ...practice, [fieldName]: truncatedValue }))
      } else {
        setPractice((practice) => ({ ...practice, [fieldName]: newValue }))
      }
    },
    [maxLength, fieldName, setPractice]
  )

  return (
    <EditableValue
      name={name}
      value={inputValue || practice[fieldName] || ""}
      saveDisabled={practice[name] === lastSavedPractice[fieldName] || practice[fieldName]?.length < 2}
      disabled={fetching || (openField && openField !== name)}
      onOpened={() => {
        setOpenField(name)
        setHighlightedField(fieldName)
        document.getElementById(scrollToId)?.scrollIntoView({ behavior: "smooth" })
        setTimeout(() => inputRef.current?.focus(), 500)
      }}
      onClosed={() => setHighlightedField(null)}
      onSave={onSave}
      onCancel={() => {
        setOpenField(null)
        setPractice(lastSavedPractice)
      }}>
      {text && <p className="mb-4 text-sm text-gray-dark">{text}</p>}
      {inputLabel && <Label htmlFor={`${name}-input`}>{inputLabel}</Label>}
      <div className="flex flex-col items-end gap-1">
        <InputTag
          ref={inputRef}
          id={`${name}-input`}
          name={name}
          value={inputValue || practice[fieldName] || ""}
          onChange={onChange || handleInputChange}
          rows={textAreaRows}
        />
        {maxLength && (
          <div className={`text-xs ${isMaxReached ? "text-red" : "text-gray-dark"}`}>
            {characterCount}/{maxLength}
          </div>
        )}
      </div>
      {children}
    </EditableValue>
  )
}

export default ContentInput
