import { WebAppBookNow } from "@heal-me/healme-widget"
import React from "react"
import { twMerge } from "tailwind-merge"

import { practiceCtaText, practiceDefaultServiceId } from "../../@core/practice/practice.utils"
import { nl2br } from "../ProProfileThemeFive/AboutSection"
import { DEFAULT_HIGHLIGHT_CLASSES } from "../WebsiteBuilder/WebsiteBuilderPage"

import CredentialsSection from "./CredentialsSection"
import SectionTitle from "./SectionTitle"

const truncateText = (text, textLimit) => {
  if (!text) return ""

  if (text.length > textLimit) {
    return text.slice(0, text.lastIndexOf(".", textLimit)) + "."
  }
  return text
}

const AboutMeContainer = ({
  practice,
  theme,
  isVisible,
  className,
  highlight,
  cardBackgroundColor,
  viewingInBuilder,
  credentialsGridClassName,
  credentialsClassName,
  credentialsBackgroundColor
}) => (
  <div
    className={twMerge(
      "p-10 transition-all duration-1000 md:px-5 min_md:w-1/2",
      className,
      isVisible
        ? "translate-x-0 translate-y-0 opacity-100"
        : "opacity-0 min_sm:translate-x-full min_sm:translate-y-full"
    )}
    style={{ backgroundColor: cardBackgroundColor }}>
    <SectionTitle theme={theme}>About me</SectionTitle>
    <p
      className={twMerge(
        "wysiwyg-content mt-10",
        highlight === "about" ? DEFAULT_HIGHLIGHT_CLASSES : "",
        highlight === "about" ? "mx-auto w-fit p-2" : ""
      )}
      dangerouslySetInnerHTML={{
        __html: nl2br(truncateText(practice.about, 1420))
      }}
    />
    <WebAppBookNow
      practiceId={practice.id}
      serviceId={practiceDefaultServiceId(practice)}
      buttonCopy={practiceCtaText(practice)}
      buttonClasses={twMerge(
        "mb-10 mt-6 w-fit rounded border border-[var(--bg-color)] px-6 py-3 transition duration-150 ease-out hover:border-[var(--hover-color)] sm:w-full",
        viewingInBuilder ? "pointer-events-none" : ""
      )}
      style={{
        color: theme.colorTextLight,
        backgroundColor: theme.colorAccent,
        "--bg-color": theme.colorAccent,
        "--hover-color": theme.colorTextLight
      }}
    />
    <CredentialsSection
      practice={practice}
      theme={theme}
      gridClasses={credentialsGridClassName}
      className={credentialsClassName}
      backgroundColor={credentialsBackgroundColor}
    />
  </div>
)

export default AboutMeContainer
