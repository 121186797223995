import React from "react"
import { twMerge } from "tailwind-merge"

import { DEFAULT_HIGHLIGHT_CLASSES } from "../WebsiteBuilder/WebsiteBuilderPage"

const LargeTextSection = ({ id, theme, text, highlight, className, backgroundColor, color }) => {
  backgroundColor ||= theme.colorGreyLightest
  color ||= theme.colorTextDark

  return (
    <section
      id={id}
      className={twMerge("scroll-mt-24 py-24 md:px-5", className)}
      style={{ backgroundColor, color, fontFamily: theme.fontHeading, fontWeight: theme.fontHeadingWeight }}>
      <div className={twMerge("container mx-auto text-center", highlight ? DEFAULT_HIGHLIGHT_CLASSES : "")}>
        {text && (
          <div
            className="wysiwyg-content text-left"
            dangerouslySetInnerHTML={{
              __html: text
            }}
          />
        )}
      </div>
    </section>
  )
}

export default LargeTextSection
