import { WebAppBookNow } from "@heal-me/healme-widget"
import uniq from "lodash/uniq"
import React from "react"
import { twMerge } from "tailwind-merge"

import { practiceCtaText, practiceDefaultServiceId } from "../../@core/practice/practice.utils"
import { OPACITY_40 } from "../../utils/utils"
import { DEFAULT_HIGHLIGHT_CLASSES } from "../WebsiteBuilder/WebsiteBuilderPage"

import BackgroundImage from "./BackgroundImage"

const locationMapping = {
  office: "in-person",
  virtual: "virtual",
  home: "at-home"
}

const BottomCTA = ({
  practice,
  theme,
  highlight,
  className,
  viewingInBuilder,
  overlayColor = "#000000" + OPACITY_40,
  ClipPath,
  ClipPathTwo
}) => {
  const locationKinds = uniq(practice.locations.map((location) => locationMapping[location.kind]))
  const locationsSentence =
    locationKinds.length === 1
      ? locationKinds[0]
      : locationKinds.length === 2
      ? locationKinds.join(" and ")
      : `${locationKinds.slice(0, -1).join(", ")}, and ${locationKinds.slice(-1)}`

  return (
    <section className="scroll-mt-24" id="bottom-cta">
      <BackgroundImage
        className={twMerge(
          "py-24 pb-12 text-center md:px-5",
          className,
          highlight === "Footer hero image" ? DEFAULT_HIGHLIGHT_CLASSES : ""
        )}
        imageUrl={practice.footerHeroImageUrl}
        overlayColor={overlayColor}
        ClipPath={ClipPath}
        ClipPathTwo={ClipPathTwo}>
        <h2
          className="text-[56px] leading-[72px] lg:text-4xl lg:leading-[48px]"
          style={{ fontFamily: theme.fontHeading, fontWeight: theme.fontHeadingWeight, color: theme.colorTextLight }}>
          Schedule an appointment now
        </h2>
        <p className="mt-4 text-lg" style={{ color: theme.colorTextLight }}>
          I&apos;m based in {practice.city}, {practice.stateAbbreviation} and offer {locationsSentence} appointments.
        </p>
        <WebAppBookNow
          practiceId={practice.id}
          serviceId={practiceDefaultServiceId(practice)}
          buttonCopy={practiceCtaText(practice)}
          buttonClasses={twMerge(
            "mb-20 mt-[30px] border border-[var(--bg-color)] px-10 py-3 transition duration-150 ease-out hover:border-[var(--hover-color)]",
            highlight === "ctaButtonText" ? DEFAULT_HIGHLIGHT_CLASSES : "",
            theme.buttonStyle === "rounded" ? "rounded-full" : "rounded",
            viewingInBuilder ? "pointer-events-none" : ""
          )}
          style={{
            color: theme.colorTextLight,
            backgroundColor: theme.colorAccent,
            "--bg-color": theme.colorAccent,
            "--hover-color": theme.colorTextLight
          }}
        />
      </BackgroundImage>
    </section>
  )
}

export default BottomCTA
