import React, { useRef, useState } from "react"
import { twMerge } from "tailwind-merge"

const ExpandableText = ({ text, maxLength, className, quotes = false, allowHtml = false, scrollUpOnClose = false }) => {
  const ref = useRef()
  text ||= ""

  const [expanded, setExpanded] = useState(false)
  const showSeeMore = text.length > maxLength && !expanded

  let displayText = showSeeMore ? text.substring(0, maxLength - 3) + "..." : text
  displayText = quotes ? `“${displayText}”` : displayText

  return (
    <div className={twMerge(scrollUpOnClose ? "scroll-mt-24 sm:scroll-mt-16" : "", className)} ref={ref}>
      {allowHtml ? (
        <span dangerouslySetInnerHTML={{ __html: expanded ? displayText.replace(/\n/g, "<br />") : displayText }} />
      ) : (
        <span>{displayText}</span>
      )}{" "}
      {showSeeMore && (
        <button className="font-bold underline" onClick={() => setExpanded(true)}>
          See more
        </button>
      )}
      {expanded && (
        <button
          className="font-bold underline"
          onClick={(e) => {
            setExpanded(false)
            if (scrollUpOnClose) {
              e.preventDefault()
              ref.current.scrollIntoView({ behavior: "smooth" })
            }
          }}>
          See less
        </button>
      )}
    </div>
  )
}

export default ExpandableText
