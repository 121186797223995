import React from "react"
import { twMerge } from "tailwind-merge"

import { ToastProvider } from "../../contexts/ToastContext"
import GraphQLProvider from "../../providers/GraphQLProvider"
import { OPACITY_40 } from "../../utils/utils"
import BottomCTA from "../ProProfileShared/BottomCTA"
import EndorsementsSection from "../ProProfileShared/EndorsementsSection"
import Footer from "../ProProfileShared/Footer"
import GallerySection from "../ProProfileShared/GallerySection"
import HeroSection from "../ProProfileShared/HeroSection"
import Map from "../ProProfileShared/Map"
import Quote from "../ProProfileShared/Quote"
import ReviewsSection from "../ProProfileShared/ReviewsSection"
import ServicesSection from "../ProProfileShared/ServicesSection"
import TopReviewsSection from "../ProProfileShared/TopReviewsSection"
import { DEFAULT_HIGHLIGHT_CLASSES } from "../WebsiteBuilder/WebsiteBuilderPage"

import AboutSection, { nl2br } from "./AboutSection"
import DiagonalDivider from "./DiagonalDivider"
import ThemeFiveNavigation from "./ThemeFiveNavigation"
import ThemeImage from "./ThemeImage"

const ProProfileThemeFive = ({ practice, highlight, styles, viewingInBuilder }) => {
  const theme = practice.theme
  const primaryColor = theme.colorPrimary
  const secondaryColor = theme.colorSecondary

  return (
    <GraphQLProvider>
      <ToastProvider>
        <main style={styles}>
          <HeroSection
            practice={practice}
            theme={theme}
            highlight={highlight}
            viewingInBuilder={viewingInBuilder}
            ClipPath={() => (
              <div className="absolute bottom-0 h-24 w-full bg-transparent lg:h-16">
                <div
                  className="absolute inset-0 bottom-[-1px] clip-path-diagonal-line"
                  style={{ backgroundColor: primaryColor }}
                />
              </div>
            )}
            Navigation={() => (
              <ThemeFiveNavigation practice={practice} theme={theme} viewingInBuilder={viewingInBuilder} />
            )}
          />
          <TopReviewsSection practice={practice} theme={theme} className="pb-4" />
          <DiagonalDivider topBackgroundColor={primaryColor} bottomBackgroundColor={theme.colorBgLight} />
          <ThemeImage
            id="services"
            src={practice.servicesSectionImageUrl}
            alt="Services"
            highlight={highlight === "Services Image"}
          />
          <ServicesSection
            practice={practice}
            theme={theme}
            className="pt-0 md:pt-0"
            viewingInBuilder={viewingInBuilder}
            id={undefined}
            hideImageInMobile={true}
          />
          <DiagonalDivider topBackgroundColor={theme.colorBgLight} bottomBackgroundColor={theme.colorGreyLightest} />
          <div
            style={{ backgroundColor: theme.colorGreyLightest }}
            className="scroll-mt-24 pb-24 pt-20 md:pb-16"
            id="value-statement">
            <ThemeImage
              className="md:mt-0"
              src={practice.valueStatementImageUrl}
              alt="Value Statement"
              highlight={highlight === "Value Statement Image"}
            />
            <div
              className={twMerge(
                "wysiwyg-content container mx-auto p-10 md:mx-5 md:mt-10 md:w-[calc(100%-40px)] md:py-5",
                highlight === "valueStatement" ? DEFAULT_HIGHLIGHT_CLASSES : ""
              )}
              style={{ backgroundColor: theme.colorBgLight }}
              dangerouslySetInnerHTML={{
                __html: nl2br(practice.valueStatement)
              }}
            />
          </div>
          <DiagonalDivider topBackgroundColor={theme.colorGreyLightest} bottomBackgroundColor={secondaryColor} />
          <AboutSection
            practice={practice}
            theme={theme}
            className="py-28"
            highlight={highlight}
            viewingInBuilder={viewingInBuilder}
          />
          <DiagonalDivider topBackgroundColor={secondaryColor} bottomBackgroundColor={theme.colorBgLight} />
          <ThemeImage
            id="reviews"
            src={practice.reviewsSectionImageUrl}
            alt="Reviews"
            highlight={highlight === "Reviews Image"}
          />
          <ReviewsSection practice={practice} theme={theme} className="pt-0" id={undefined} />
          <EndorsementsSection practice={practice} theme={theme} />
          <DiagonalDivider topBackgroundColor={theme.colorBgLight} bottomBackgroundColor={theme.colorGreyLightest} />
          <GallerySection practice={practice} theme={theme} highlight={highlight === "Gallery photos"} />
          <DiagonalDivider topBackgroundColor={theme.colorGreyLightest} bottomBackgroundColor={secondaryColor} />
          <Quote
            theme={theme}
            text={practice.quote}
            author={practice.quoteAuthor}
            id="quote"
            highlight={highlight === "quote"}
            backgroundColor={secondaryColor}
          />
          <BottomCTA
            practice={practice}
            theme={theme}
            overlayColor={theme.colorBgDark + OPACITY_40}
            highlight={highlight}
            viewingInBuilder={viewingInBuilder}
            className="pt-[196px]"
            ClipPath={() => (
              <div className="absolute top-[-1px] h-24 w-full bg-transparent lg:h-16">
                <div
                  className="absolute inset-0 clip-path-diagonal-line-reversed"
                  style={{ backgroundColor: secondaryColor }}
                />
              </div>
            )}
            ClipPathTwo={() => (
              <div className="absolute bottom-0 h-24 w-full bg-transparent lg:h-16">
                <div
                  className="absolute inset-0 bottom-[-1px] clip-path-diagonal-line"
                  style={{ backgroundColor: primaryColor }}
                />
              </div>
            )}
          />
          <Map
            practice={practice}
            theme={theme}
            ClipPath={() => (
              <div className="absolute top-[-1px] h-24 w-full bg-transparent lg:h-16">
                <div
                  className="absolute inset-0 clip-path-diagonal-line-reversed"
                  style={{ backgroundColor: primaryColor }}
                />
              </div>
            )}
            ClipPathTwo={() => (
              <div className="absolute bottom-0 h-24 w-full bg-transparent lg:h-16">
                <div
                  className="absolute inset-0 bottom-[-1px] clip-path-diagonal-line"
                  style={{ backgroundColor: primaryColor }}
                />
              </div>
            )}
          />
          <Footer practice={practice} theme={theme} viewingInBuilder={viewingInBuilder} />
        </main>
      </ToastProvider>
    </GraphQLProvider>
  )
}

export default ProProfileThemeFive
