import React from "react"
import { twMerge } from "tailwind-merge"

import useIntersectionObserver from "../../hooks/useIntersectionObserver"
import AboutMeContainer from "../ProProfileShared/AboutMeContainer"
import { DEFAULT_HIGHLIGHT_CLASSES } from "../WebsiteBuilder/WebsiteBuilderPage"

const AboutSection = ({ practice, theme, highlight, className, viewingInBuilder }) => {
  const color = theme.colorTextDark
  const [isVisible, containerRef] = useIntersectionObserver()

  return (
    <section id="about" style={{ color }} className={twMerge("", className)}>
      <div className="relative flex flex-col min_md:flex-row">
        <div className="min_md:hidden">
          <img
            src={practice.user.profilePhotoUrl}
            alt={practice.user.name}
            className={"h-[240px] w-full object-cover"}
          />
        </div>
        <div className="min_md:w-1/2 min_md:pr-12" ref={containerRef}>
          <AboutMeContainer
            practice={practice}
            theme={theme}
            isVisible={isVisible}
            className={twMerge(
              "w-full min_md:m-10 min_md:p-16",
              isVisible
                ? "opacity-100 min_md:translate-x-[136px] min_md:translate-y-0"
                : "opacity-0 min_md:-translate-x-full min_md:-translate-y-full"
            )}
            cardBackgroundColor={theme.colorGreyLightest}
            viewingInBuilder={viewingInBuilder}
          />
        </div>
        <div className="md:hidden min_md:w-1/2">
          <img
            src={practice.user.profilePhotoUrl}
            alt={practice.user.name}
            className={twMerge("h-full w-full object-cover", highlight === "Headshot" ? DEFAULT_HIGHLIGHT_CLASSES : "")}
          />
        </div>
      </div>
    </section>
  )
}

export default AboutSection
