import { practiceDefaultServiceId, practiceCtaText } from "@core/practice/practice.utils"
import { WebAppBookNow } from "@heal-me/healme-widget"
import React from "react"
import { twMerge } from "tailwind-merge"

import { DEFAULT_HIGHLIGHT_CLASSES } from "../WebsiteBuilder/WebsiteBuilderPage"

import ReviewStars from "./ReviewStars"

const HeroSection = ({ practice, theme, highlight, ClipPath, Navigation, viewingInBuilder, showStars = false }) => {
  const defaultServiceId = practiceDefaultServiceId(practice)
  const ctaText = practiceCtaText(practice)

  const url = practice.headerHeroImageUrl.replace(/\+/g, "%20").replace(/\(/g, "%28").replace(/\)/g, "%29")

  return (
    <div
      id="hero-section"
      className={twMerge(
        "relative flex min-h-[700px] items-center justify-center bg-cover bg-center bg-no-repeat text-center bg-blend-multiply",
        highlight === "Hero image" ? DEFAULT_HIGHLIGHT_CLASSES : ""
      )}
      style={{
        backgroundImage: `url(${url})`,
        color: theme.colorTextLight
      }}>
      <div className="container z-10 mx-auto md:p-8">
        <h1
          className={twMerge(
            "text-[56px] leading-[72px] lg:text-4xl lg:leading-[48px]",
            highlight === "websiteHeadline" ? DEFAULT_HIGHLIGHT_CLASSES : ""
          )}
          style={{ fontFamily: theme.fontHeading, fontWeight: theme.fontHeadingWeight, color: theme.colorTextLight }}>
          {practice.websiteHeadline}
        </h1>
        <p
          className={twMerge(
            "mt-4 text-xl leading-8",
            highlight === "subHeadline" ? DEFAULT_HIGHLIGHT_CLASSES : "",
            highlight === "subHeadline" ? "mx-auto w-fit px-8 py-2" : ""
          )}>
          {practice.subHeadline}
        </p>
        <div className="mx-auto my-7 text-center">
          <WebAppBookNow
            practiceId={practice.id}
            serviceId={defaultServiceId}
            buttonCopy={ctaText}
            buttonClasses={twMerge(
              "border border-[var(--bg-color)] px-10 py-3 transition duration-150 ease-out hover:border-[var(--hover-color)]",
              highlight === "ctaButtonText" ? DEFAULT_HIGHLIGHT_CLASSES : "",
              theme.buttonStyle === "rounded" ? "rounded-full" : "rounded",
              viewingInBuilder ? "pointer-events-none" : ""
            )}
            style={{
              color: theme.colorTextLight,
              backgroundColor: theme.colorAccent,
              "--bg-color": theme.colorAccent,
              "--hover-color": theme.colorTextLight
            }}
          />
        </div>
        {showStars && (
          <div className="flex items-center justify-center gap-4">
            <ReviewStars rating={practice.averageRating} starColor={theme.colorReviewStars} />
            <span>{practice.reviews.length} reviews</span>
          </div>
        )}
      </div>
      <Navigation />
      <div className="absolute inset-0 h-full w-full bg-black-real/40" />
      {ClipPath && <ClipPath />}
    </div>
  )
}

export default HeroSection
