import React from "react"

import { practiceCtaText, practiceDefaultServiceId } from "../../@core/practice/practice.utils"
import { Select, Label, InputWithLabel } from "../../components/shared/Inputs"
import { Divider } from "../../components/shared/Layout"
import QuillEditor from "../../components/shared/QuillEditor"

import ContentInput from "./ContentInput"
import GalleryUpload from "./GalleryUpload"
import HeadshotUpload from "./HeadshotUpload"
import HeroImageUpload from "./HeroImageUpload"
import { useWebsiteBuilder } from "./WebsiteBuilderContext"

const ContentTab = () => {
  const { practice, setPractice } = useWebsiteBuilder()

  return (
    <div>
      <div>
        <HeroImageUpload
          name="Hero image"
          fieldName="headerHeroImageUrl"
          description="header_hero_image"
          scrollToId="hero-section"
        />
        <Divider />
        <ContentInput
          name="Headline"
          fieldName="websiteHeadline"
          text="Create a powerful headline that instantly captures attention and communicates your core message."
          scrollToId="hero-section"
        />
        <Divider />
        <ContentInput
          name="Subheadline"
          fieldName="subHeadline"
          textAreaRows={3}
          text="Add a brief subheadline to expand on your main message and engage visitors."
          scrollToId="hero-section"
        />
        <Divider />
        <ContentInput
          name="Call to action button"
          fieldName="ctaButtonText"
          inputValue={practiceCtaText(practice)}
          inputLabel="Call to action text"
          text="Select a clear and compelling call-to-action that guides visitors toward the next step you want them to take."
          scrollToId="hero-section">
          <div className="mt-4">
            <Label htmlFor="service-select">Service</Label>
            <Select
              id="service-select"
              options={practice.services.map((service) => ({
                value: service.id,
                label: service.name
              }))}
              defaultOption="-- select a service --"
              onChange={(e) => setPractice((prev) => ({ ...prev, defaultServiceId: e.target.value }))}
              value={Number(practiceDefaultServiceId(practice)) || ""}
            />
            <p className="mt-2 text-sm text-gray-dark">Go to your Services to add additional options.</p>
          </div>
        </ContentInput>
        <Divider />
        <HeroImageUpload
          name="Services Image"
          fieldName="servicesSectionImageUrl"
          description="services_section"
          scrollToId="services"
        />
        <Divider />
        <HeroImageUpload
          name="Value Statement Image"
          fieldName="valueStatementImageUrl"
          description="value_statement"
          scrollToId="value-statement"
        />
        <Divider />
        <ContentInput
          InputTag={QuillEditor}
          inputValue={practice.valueStatement}
          onChange={(e) => setPractice((prev) => ({ ...prev, valueStatement: e }))}
          scrollToId="value-statement"
          name="Value statement"
          fieldName="valueStatement"
          text="Write a statement about how you assist clients and the specific benefits they can expect from your services."
        />
        <Divider />
        <HeadshotUpload />
        <Divider />
        <ContentInput
          name="About me"
          fieldName="about"
          scrollToId="about"
          InputTag={QuillEditor}
          inputValue={practice.about}
          onChange={(e) => setPractice((prev) => ({ ...prev, about: e }))}
          maxLength={1420}
          text="Share a brief bio that highlights your background, your values, and your unique approach to working with clients."
        />
        <Divider />
        <HeroImageUpload
          name="Reviews Image"
          fieldName="reviewsSectionImageUrl"
          description="reviews_section"
          scrollToId="reviews"
        />
        <Divider />
        <ContentInput
          name="Quote"
          textAreaRows={3}
          scrollToId="quote"
          text="Include a meaningful or testimonial quote to build trust and create a deeper connection with your audience.">
          <InputWithLabel
            className="mt-2"
            label="Author"
            value={practice.quoteAuthor || ""}
            onChange={(e) => setPractice((prev) => ({ ...prev, quoteAuthor: e.target.value }))}
          />
        </ContentInput>
        <Divider />
        <GalleryUpload />
        <Divider />
        <HeroImageUpload
          name="Footer hero image"
          fieldName="footerHeroImageUrl"
          description="footer_hero_image"
          scrollToId="bottom-cta"
        />
        <Divider />
      </div>
    </div>
  )
}

export default ContentTab
