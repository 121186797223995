import React from "react"
import { twMerge } from "tailwind-merge"

import { nl2br } from "../ProProfileThemeFive/AboutSection"
import { DEFAULT_HIGHLIGHT_CLASSES } from "../WebsiteBuilder/WebsiteBuilderPage"

const ValueStatementSection = ({ practice, theme, highlight }) => {
  const { valueStatement } = practice

  const imageUrl = practice.valueStatementImageUrl

  return (
    <section
      id="value-statement"
      style={{ backgroundColor: theme.colorSecondary }}
      className="w-full py-24 md:px-5 md:py-16">
      <div className="container mx-auto flex w-full items-center justify-between gap-10 md:flex-col-reverse">
        <div className="w-1/2 px-6 py-10 md:w-full" style={{ backgroundColor: theme.colorWhite }}>
          <div
            className={twMerge(highlight === "valueStatement" ? DEFAULT_HIGHLIGHT_CLASSES : "")}
            style={{ color: theme.colorTextDark }}>
            <div
              className="wysiwyg-content"
              dangerouslySetInnerHTML={{
                __html: nl2br(valueStatement)
              }}
            />
          </div>
          {/* <div className="mt-8 text-left md:text-center">
            <WebAppBookNow
              practiceId={practice.id}
              serviceId={practice.defaultServiceId}
              buttonCopy="Book now"
              buttonClasses={twMerge(
                "border border-[var(--bg-color)] px-10 py-3 hover:border-[var(--hover-color)] md:w-full",
                theme.buttonStyle === "rounded" ? "rounded-full" : "rounded",
                viewingInBuilder ? "pointer-events-none" : ""
              )}
              style={{
                color: theme.colorTextLight,
                backgroundColor: theme.colorAccent,
                "--bg-color": theme.colorAccent,
                "--hover-color": theme.colorTextLight
              }}
            />
          </div> */}
        </div>
        <div className="w-1/2 md:w-full">
          <img src={imageUrl} alt={practice.user.name} className="aspect-square w-full object-cover md:h-60" />
        </div>
      </div>
    </section>
  )
}

export default ValueStatementSection
