import React, { useState } from "react"
import { twMerge } from "tailwind-merge"

import { Button } from "../../components/shared/Buttons"
import { ModalBackdrop } from "../../components/shared/Modal"

const GalleryImagesSection = ({ images, sectionClasses }) => {
  const [showImagesModal, setShowImagesModal] = useState(false)

  const galleryImages = images.map((image) => {
    const handle = image.filestackPhoto.large?.webp || image.filestackPhoto.original?.any
    return { id: image.id, url: `https://cdn.filestackcontent.com/${handle}` }
  })

  let firstImages = galleryImages.slice(0, 3)

  const onClick = () => setShowImagesModal(true)

  return (
    <>
      <section className={twMerge("mt-8", sectionClasses)}>
        <div
          className={`relative flex gap-[23px] md:-mr-6 md:overflow-x-auto md:pr-6 ${
            firstImages.length > 2 ? "justify-between" : ""
          }`}>
          {firstImages.map((image) => (
            <div
              key={image.id}
              className="aspect-square max-w-[230px] flex-1 md:w-[180px] md:flex-none md:shrink-0"
              onClick={onClick}
              role="button">
              <img src={image.url} alt="Gallery image" className="h-full w-full rounded-lg object-cover" />
            </div>
          ))}
          {images.length > 3 && (
            <Button type="tertiary" className="absolute bottom-4 right-4 md:hidden" onClick={onClick}>
              See all photos
            </Button>
          )}
        </div>
      </section>
      <ModalBackdrop hideModal={() => setShowImagesModal(false)} visible={showImagesModal}>
        <div className="flex w-full flex-col items-center justify-center gap-4 py-16 sm:-ml-6 sm:box-content sm:px-6 sm:py-0">
          {galleryImages.map((image) => (
            <div
              key={image.id}
              className="h-[600px] w-[600px] rounded-lg md:aspect-square md:h-auto md:w-full sm:rounded-none">
              <img
                src={image.url}
                alt="Gallery image"
                className="h-full w-full rounded-lg object-cover sm:rounded-none"
              />
            </div>
          ))}
        </div>
      </ModalBackdrop>
    </>
  )
}

export default GalleryImagesSection
