import React, { useEffect, useRef, useState } from "react"
import { twMerge } from "tailwind-merge"

import useIntersectionObserver from "../../hooks/useIntersectionObserver"

import HealMeVerifiedBadge from "./HealMeVerifiedBadge"
import Review from "./Review"
import ReviewStars from "./ReviewStars"
import SectionTitle from "./SectionTitle"
import SeeMore from "./SeeMore"

const ReviewsSection = ({ practice, theme, className, backgroundColor, color, showTitle = true, id = "reviews" }) => {
  backgroundColor ||= theme.colorBgLight
  color ||= theme.colorTextDark

  const isMobile = window.innerWidth < 768
  const defaultReviewsShown = isMobile ? 4 : 10
  const reviews = practice.reviews.filter((review) => review.review)
  const [expanded, setExpanded] = useState(false)

  const [isVisible, containerRef] = useIntersectionObserver()

  const reviewsContainerRef = useRef(null)

  useEffect(() => {
    if (reviewsContainerRef.current) {
      reviewsContainerRef.current.style.maxHeight = expanded ? `${reviewsContainerRef.current.scrollHeight}px` : "0px"
    }
  }, [expanded, reviews])

  return (
    <section id={id} className={twMerge("scroll-mt-24 pt-24 md:px-5", className)} style={{ backgroundColor, color }}>
      <div className="container mx-auto">
        {showTitle && (
          <SectionTitle className="mb-4 text-center" theme={theme}>
            My Reviews
          </SectionTitle>
        )}
        <div className="mb-10 flex items-center justify-center gap-4">
          <ReviewStars rating={practice.averageRating} starColor={theme.colorReviewStars} />
          <span>{practice.reviews.length} reviews</span>
        </div>
        <div className="mb-10 flex w-full justify-center">
          <HealMeVerifiedBadge theme={theme} backgroundColor={backgroundColor} color={color} />
        </div>
        <div
          ref={containerRef}
          className={`grid grid-cols-2 gap-10 transition-all duration-1000 lg:grid-cols-1 ${
            isVisible ? "translate-y-0 opacity-100" : "-translate-y-24 opacity-0"
          }`}>
          {reviews.slice(0, defaultReviewsShown).map((review) => (
            <Review
              key={`review-${review.id}`}
              review={review}
              practice={practice}
              starColor={theme.colorReviewStars}
            />
          ))}
        </div>
        <div
          ref={reviewsContainerRef}
          className="mt-10 grid grid-cols-2 gap-10 overflow-hidden transition-all duration-1000 lg:grid-cols-1"
          style={{ maxHeight: 0 }}>
          {reviews.slice(defaultReviewsShown).map((review) => (
            <Review
              key={`review-${review.id}`}
              review={review}
              practice={practice}
              starColor={theme.colorReviewStars}
            />
          ))}
        </div>
        {reviews.length > defaultReviewsShown && (
          <SeeMore
            className="pt-10 text-opacity-50"
            buttonClassName="hover:text-[var(--hover-color)]"
            buttonStyle={{ color, "--hover-color": theme.colorAccent }}
            buttonText="See more reviews"
            onClick={() => {
              if (expanded) document.getElementById("reviews").scrollIntoView({ behavior: "smooth" })
              setExpanded(!expanded)
            }}
          />
        )}
      </div>
    </section>
  )
}

export default ReviewsSection
